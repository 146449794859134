import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import createApi from "../../utils/api";
import {setAuthUser} from "../../store";
import {useDispatch} from "react-redux";
import Loader from "../loader_folder/Loader";
import Header from "../header/Header";
const LoginWithToken = () => {
    const api = createApi();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    useEffect(() => {
        const token = searchParams.get('code');
        if (token) {
            handleTokenLogin(token);
        } else {
            setError(
                "Please enter the unique property id in the URL to access your property"
            );
            setLoading(false);
        }
    }, [searchParams]);

    const handleTokenLogin = async (token) => {
        try {
            const response = await api.get(`/homeownerlogin/${token}`);
            localStorage.setItem("token-type", response.data.usertype);
            localStorage.setItem("user_name", response.data.name);
            dispatch(setAuthUser());
            navigate("/dashboard");
        } catch (error) {
            localStorage.removeItem("token-type");
            localStorage.removeItem("user_name");
            setError("Property Not Found!");
        }
        setLoading(false);
    };
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <Header />
            {error && <div className="mt-10 mx-6 info_style"> {error}</div>}
        </>
    );
};

export default LoginWithToken;
