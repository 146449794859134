import Mainfooter from "../footer/Mainfooter";
import Header from "../header/Header";
// import axios from "axios";
import Banner from "./Banner";
// import createApi from "../../utils/api";
import Dashboard from "../agent/Dashboard";
import HomeOwnerDashboard from "../homeowner/Dashboard";
// import Header2 from "../header/Header2";
// import {
//   fetchUserProfile,
//   fetchLenders,
//   fetchInspectors,
//   fetchTitles,
//   fetchPropertyList,
// } from "../../store";
// import Loader from "../loader_folder/Loader";

function Homepage() {

  return (
    <>
      {localStorage.getItem("token-type") === "agent"
       ? (
        <>
          <Dashboard />
        </>
      ) : (
        <>
          <HomeOwnerDashboard />
        </>
      )}
    </>
  );
}

export default Homepage;
