import React, { useEffect, useState } from "react";
import createApi from "../../utils/api";
import Header from "../admin/Header";
import Sidebar from "../admin/sidebar";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const MySwal = withReactContent(Swal);

function Globalpropertyvalues() {
    const api = createApi();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [editMode, setEditMode] = useState(null);
    const [message, setMessage] = useState("");
    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState('asc');

    const columns = [
        { name: "No", selector: row => row.id, sortable: true, sortField: 'id' },
        { name: "Annual Percent", selector: row => row.annualpercent, sortable: true, sortField: 'rate' },
        { name: "Date From", selector: row => row.date_from, sortable: true, sortField: 'date_from' },
        { name: "Date To", selector: row => row.date_to, sortable: true, sortField: 'date_to' },
        {
            name: "Actions",
            cell: row => (
                <>
                    <button
                        className="btn btn-link"
                        onClick={() => handleEdit(row)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                        <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-link" onClick={() => handleDelete(row.id)}>
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            ),
        },
    ];

    const validationSchema = Yup.object().shape({
        annualpercentage: Yup.number()
            .required("Required")
            .min(-100, "Min -100")
            .max(100, "Max 100").test(
                "is-integer",
                "Must be an integer",
                (value) => Number.isInteger(value)
            ),
        datefrom: Yup.string()
            .required("Required"),
            //.matches(/^\d{2}\-\d{2}\-\d{4}$/, "Date must be in DD-MM-YYYY format"),
        dateto: Yup.string()
            .required("Required")
            //.matches(/^\d{2}\-\d{2}\-\d{4}$/, "Date must be in DD-MM-YYYY format"),
    });

    const fetchData = async (page = 1,perPage = 10,sortColumn = 'id',sortDirection = 'asc') => {
        setLoading(true);
        const start = (page - 1) * perPage;
        const response = await api.get(`/admin/globalproperty?start=${start}&length=${perPage}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
        const formattedData = response.data.data.map(value => ({
            id: value.id,
            annualpercent: value.rate,
            date_from: value.date_from,
            date_to: value.date_to,
        }));
        setData(formattedData);
        setTotalRows(response.data.recordsTotal);
        setLoading(false);
    };

    useEffect(() => {
        fetchData(1,perPage,sortColumn,sortDirection);
    }, []);
    const handleSort = (column, direction) => {
        setSortColumn(column.sortField);
        setSortDirection(direction);
        setLoading(true);
        setTimeout( () => {
            fetchData(1,perPage,sortColumn,sortDirection);
        }, 100);
    };
    const handlePageChange = page => fetchData(page);
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page,perPage,sortColumn,sortDirection);
    };

    const handleEdit = record => {
        setEditMode(record);
    };

    const handleDelete = id => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
        }).then(result => {
            setLoading(true);
            if (result.isConfirmed) {
                api.delete(`/admin/globalproperty/${id}`).then(() => {
                    setMessage("Property deleted successfully");
                    fetchData(1,perPage,sortColumn,sortDirection);
                });
            }
        });
    };
    const resetModal = () => {
        setEditMode(null);
    };
    return (
        <>
            <Header />
            <section className="dashboard">
                <div className="container-fluid">
                    <Sidebar />
                    <div className="inspection_reques maintenance globalpropertyvalues">
                        <h2>
                            Global Property Values
                            <button
                                className="btn btn-primary float-end"
                                onClick={resetModal}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                            >
                                <i className="fa fa-plus"></i> Add New
                            </button>
                        </h2>
                        {message && <div className="alert alert-success">{message}</div>}
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            sortServer
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </section>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editMode ? "Edit Property" : "Add Property"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{
                                    annualpercentage: editMode?.annualpercent || "",
                                    datefrom: editMode?.date_from || "",
                                    dateto: editMode?.date_to || "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { resetForm }) => {
                                    const endpoint = editMode
                                        ? `/admin/globalproperty/${editMode.id}`
                                        : "/admin/globalproperty";
                                    const formData = new FormData();
                                    Object.keys(values).forEach(key => {
                                        formData.append(key, values[key]);
                                    });
                                    if (editMode) {
                                        formData.append("_method", "PUT");
                                    }
                                    api.post(endpoint, formData).then(() => {
                                        setMessage(
                                            editMode
                                                ? "Property updated successfully"
                                                : "New property added successfully"
                                        );
                                        fetchData(1,perPage,sortColumn,sortDirection);
                                        resetForm();
                                        setEditMode(null);
                                        document.querySelector("#exampleModal .btn-close").click();
                                    });
                                }}
                                enableReinitialize
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-12">
                                                <label>Annual Percentage Change</label>
                                                <div className="input-group">
                                                    <Field name="annualpercentage" type="number" className="form-control" />
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <ErrorMessage name="annualpercentage" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Date From</label>
                                                <Field name="datefrom" type="date" className="form-control" />
                                                <ErrorMessage name="datefrom" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-6">
                                                <label>Date To</label>
                                                <Field name="dateto" type="date" className="form-control" />
                                                <ErrorMessage name="dateto" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Save Changes</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Globalpropertyvalues;
