import React, { useEffect, useState } from "react";
import Header from '../admin/Header';
import Sidebar from '../admin/sidebar';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import createApi from "../../utils/api";
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function GlobalAprValues() {
    const api = createApi();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [message, setMessage] = useState('');

    const columns = [
        {
            name: 'Years',
            selector: row => row.years,
        },
        {
            name: 'Percent',
            selector: row => row.percent,
            cell: row => (
                <button
                    className="btn btn-link"
                    onClick={() => handleEditClick(row)}
                >
                    {row.percent}%
                </button>
            ),
        },
    ];

    // Fetch data from API
    const fetchData = async () => {
        setLoading(true);
        const response = await api.get(`/admin/globalapr`);
        setData(response.data.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Handle edit button click
    const handleEditClick = (record) => {
        setSelectedRecord(record);
        setEditModalShow(true);
    };

    // Validation schema with Yup
    const validationSchema = Yup.object().shape({
        percent: Yup.number()
            .min(0, "Percent must be at least 0")
            .max(100, "Percent cannot exceed 100")
            .required("Percent is required"),
    });

    // Submit updated data
    const handleFormSubmit = (values, { setSubmitting }) => {
        const formdata = new FormData();
        formdata.append('percent', values.percent);
        formdata.append("_method", 'PATCH');
        api.post(`/admin/globalapr/${selectedRecord.id}`, formdata)
            .then((response) => {
                setMessage("Updated successfully!");
                fetchData();
                setEditModalShow(false);
                setSubmitting(false);
            })
            .catch((error) => {
                console.log("Error updating:", error);
                setSubmitting(false);
            });
    };

    return (
        <>
            <Header />
            <section className="dashboard">
                <div className="container-fluid">
                    <Sidebar />
                    <div className="inspection_reques maintenance globalpropertyvalues">
                        {message && <div className="alert alert-success">{message}</div>}
                        <h2>Global APR Values</h2>
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                className="table"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Edit Modal */}
            {selectedRecord && (
                <Modal show={editModalShow} onHide={() => setEditModalShow(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Percent Value</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{ percent: selectedRecord.percent }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="form-group">
                                        <label>Percent</label>
                                        <Field
                                            name="percent"
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter new percent"
                                        />
                                        <ErrorMessage
                                            name="percent"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setEditModalShow(false)}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            Save Changes
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}
export default GlobalAprValues;
