import {Link, useNavigate} from "react-router-dom";
import Forgotpassword from "../forgotpassword/Forgotpassword";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import createApi from "../../utils/api";
import Sendotp from "../homepage/sendotp";
import "react-toastify/dist/ReactToastify.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import { setAuthUser } from "../../store";
import * as Yup from "yup";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .max(18, 'Password should be less than 18 characters')
});

function Login() {
    const dispatch = useDispatch();
    const api = createApi();
    const navigate = useNavigate();
    const [viewpassword, setviewpassword] = useState(false);
    const [optPage, setOptPage] = useState(0);
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");

    const handleSubmit = async (values, {setSubmitting, setErrors}) => {
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('remember_me', values.remember_me);
        api
            .post("/login", formData)
            .then(function (response) {
                localStorage.setItem("token-type", response.data.usertype);
                localStorage.setItem("user_name", response.data.name);
                dispatch(setAuthUser());
                navigate("/dashboard");
            }).catch(function (error) {
            if (error.response && error.response.status === 422) {
                const validationErrors = error.response.data.errors;
                setErrors(validationErrors);
            } else if (error.response && error.response.status === 403) {
                setEmail(values.email);
                setOptPage(1);
            } else {
                console.log("error", error);
            }
        });
        setSubmitting(false);
    };
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };
    return (<div>
        {optPage === 1 ? (<Sendotp
            emailId={email}
            setOtpStatus={setOptPage}
        />) : (<Formik
            initialValues={{
                email: '', password: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({isSubmitting}) => (<>
                <link rel="stylesheet" href="/../asset/css/style.css"/>
                <section className="login text-center">
                    <div className="container">
                        <div className="in_login">
                            <Link to="/">
                                <img src="/../asset/images/logo.png" alt="logo"/>
                            </Link>
                            <Form className="form-signin text-start mt-4">
                                <h1>Sign In</h1>
                                <br/>

                                <div className="form-group">
                                    <label>Email</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                    />
                                    <ErrorMessage name="email" component="span" className="errormessage"/>
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <span className="password d-block">
                                      <Field
                                          type={viewpassword ? "text" : "password"}
                                          name="password"
                                          className="form-control"
                                          placeholder="Enter Password"
                                      />
                                      <i
                                          className={viewpassword ? "far fa-eye" : "fa fa-eye-slash"}
                                          aria-hidden="true"
                                          onClick={togglePassword}
                                      ></i>
                                    </span>
                                    <ErrorMessage name="password" component="span" className="errormessage"/>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="checkbox">
                                            <label>
                                                <Field name="remember_me" type="checkbox" value="1"/> Remember
                                                me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <a
                                            className="forg"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                        >
                                            Forgot Password
                                        </a>
                                    </div>
                                </div>
                                <button className="btn" type="submit" disabled={isSubmitting}>
                                    Log In Account
                                </button>
                                <p className="text-center">
                                    Don’t have an account? <Link to="/register">Sign Up</Link>
                                </p>
                            </Form>
                        </div>
                    </div>
                </section>
                <Forgotpassword/>
            </>)}
        </Formik>)}
    </div>);
}
export default Login;
