import Footer from '../footer/Footer';
import Header from '../header/Header';
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import $ from "jquery";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import createApi from "../../utils/api";
import InputMask from "react-input-mask";
import Sendotp from "../homepage/sendotp";

const phonePattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const namePattern = /^[a-zA-Z\s]+$/;
const validationSchema = Yup.object({
    name: Yup.string()
        .matches(namePattern, 'Kindly Check your Name')
        .min(2, 'Kindly Check your Name').required('Name is required')
        .max(50, 'Kindly Check your Name')
        .required('Name is required'),
    company: Yup.string()
        .min(2, 'Company Name should be at least 2 characters')
        .max(50, 'Company Name should be less than 50 characters')
        .required('Company Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    cellnumber: Yup.string()
        .matches(phonePattern, 'Not Valid Mobile Number')
        .required('Phone Number is required'),
    password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .max(18, 'Password should be less than 18 characters')
        .matches(/[A-Z]/, 'Required Strong Password')
        .matches(/\d/, 'Required Strong Password')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Required Strong Password')
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
    profileImage: Yup.mixed()
        .nullable()
        .test("fileSize", "Image Not be greater than 5 Mb", value => {
            return value ? value.size <= 5120 * 1024 : true;
        })
        .test("fileFormat", "Please Select Valid Image", value => {
            return value ? ["image/jpeg", "image/png", "image/gif"].includes(value.type) : true;
        }),
});

function Registertitlecompany() {
    const navigate = useNavigate();
    const [checkedbox, setCheckedbox] = useState(false);
    const [viewpassword, setviewpassword] = useState(false);
    const [viewpassword1, setviewpassword1] = useState(false);
    const [optPage, setOptPage] = useState(0);
    const [email, setEmail] = useState("");
    const api = createApi();
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };
    const checkboxclick = () => {
        setCheckedbox(!checkedbox)
    }
    const togglePassword1 = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword1(!viewpassword1);
    };
    const handleSubmit = async (values, {setSubmitting,setErrors}) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('company', values.company);
        formData.append('cellnumber', values.cellnumber);
        formData.append('password', values.password);
        formData.append('password_confirmation', values.password_confirmation);
        if (values.profileImage) {
            formData.append('profileImage', values.profileImage);
        }
        try {
            $(".addclass").hide();
            $("#buttonreplacement").show();
            api
                .post("/registertitlecompany", formData)
                .then(function (response) {
                    localStorage.setItem("token-type", response.data.usertype);
                    localStorage.setItem("user_name", response.data.name);
                    navigate("/dashboard");
                })
                .catch(function (error) {
                    $("#buttonreplacement").hide();
                    if (error.response && error.response.status === 422) {
                        const validationErrors = error.response.data.errors;
                        setErrors(validationErrors);
                    } else if (error.response && error.response.status === 403) {
                        setEmail(values.email);
                        setOptPage(1);
                    } else {
                        console.log("error", error);
                    }
                });
            $(".addclass").show();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        setSubmitting(false);
    };
    return (
        <>
            <Header />
            {optPage === 1 ? (
                <Sendotp
                    emailId={email}
                    setOtpStatus={optPage}
                />
            ) : (
                <Formik
                    initialValues={{
                        name: '', email: '', company: '', cellnumber: '', password: '', confirm_password: '', profileImage: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, isValid }) => (
                        <section className="login register lenderReg">
                            <div className="container">
                                <Form className="text-start">
                                    <h1>Register as Title Company</h1>
                                    <div className="form-signin">
                                        <h4>Personal Information</h4>
                                        <div className="form-group">
                                            <label className="st">
                                                Full Name<span>*</span>:
                                            </label>
                                            <Field type="text" name="name" className="form-control"
                                                   placeholder="Enter full name"/>
                                            <ErrorMessage name="name" component="span" className="errormessage"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">Company Name<span>*</span>:</label>
                                            <Field type="text" name="company" className="form-control"
                                                   placeholder="Enter your Company name"/>
                                            <ErrorMessage name="company" component="span" className="errormessage"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">
                                                Email<span>*</span>:
                                            </label>
                                            <Field type="email" name="email" className="form-control"
                                                   placeholder="Enter email address"/>
                                            <ErrorMessage name="email" component="span" className="errormessage"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">
                                                Mobile Number<span>*</span>:
                                            </label>
                                            <Field name="cellnumber">
                                                {({field}) => (<InputMask
                                                    {...field}
                                                    mask="999-999-9999"
                                                    maskChar=""
                                                    className="form-control inputnumber"
                                                    inputMode="numeric"
                                                    placeholder="Enter mobile number"
                                                />)}
                                            </Field>
                                            <ErrorMessage name="cellnumber" component="span" className="errormessage"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">
                                                Password<span>*</span>:
                                            </label>
                                            <span className="password d-block">
                              <Field
                                  type={viewpassword ? "text" : "password"}
                                  name="password"
                                  className="form-control"
                                  placeholder="Password"
                              />
                              <i
                                  className={viewpassword ? "far fa-eye" : "fa fa-eye-slash"}
                                  onClick={togglePassword}
                              ></i>
                              <ErrorMessage name="password" component="span" className="errormessage"/>
                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">
                                                Confirm Password<span>*</span>:
                                            </label>
                                            <span className="password d-block">
                              <Field
                                  type={viewpassword1 ? "text" : "password"}
                                  name="confirm_password"
                                  className="form-control"
                                  placeholder="Confirm Password"
                              />
                              <i
                                  className={viewpassword1 ? "far fa-eye" : "fa fa-eye-slash"}
                                  onClick={togglePassword1}
                              ></i>
                              <ErrorMessage name="confirm_password" component="span" className="errormessage"/>
                            </span>
                                        </div>
                                        <div className="form-group">
                                            <label className="st">Profile Photo:</label>
                                            <div className="input-group custom-file-button">
                                                <span className="input-group-text"
                                                      htmlFor="profileImage">Choose</span>
                                                <input
                                                    type="file"
                                                    id="profileImage"
                                                    className="form-control"
                                                    name="profileImage"
                                                    onChange={(event) => {
                                                        setFieldValue("profileImage", event.currentTarget.files[0]);
                                                    }}
                                                />
                                            </div>
                                            <ErrorMessage name="profileImage" component="span"
                                                          className="errormessage"/>
                                        </div>
                                        <div className="form-group">
                                            <div className="checkbox">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxclick"
                                                        onClick={checkboxclick}
                                                        value="remember-me"
                                                    />
                                                    I agree to the{" "}
                                                    <Link className="tnc" to="/termsandcondition">
                                                        Terms of Service{" "}
                                                    </Link>
                                                    and{" "}
                                                    <Link className="privacy" to="/privacypolicy">
                                                        Privacy Policy{" "}
                                                    </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-6">
                                            <p className="mt-0">** Contrary to popular belief, Lorem lpsum is not simply
                                                random
                                                text.<br/>
                                                It has roots in a piece of classNameical Latin literature</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <button className={checkedbox ? 'btn addclass' : 'btn notactive'}
                                                    type={checkedbox ? 'submit' : 'button'}>Create an Account
                                            </button>
                                            <div id="buttonreplacement" style={{marginLeft: "30px"}}>
                                                <img src="//www.willmaster.com/images/preload.gif" alt="loading..."/>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )}
                </Formik>
            )}
            <Footer/>
        </>
    );
}

export default Registertitlecompany;


