import { createAsyncThunk } from "@reduxjs/toolkit";
import createApi from "../../utils/api";

const fetchHomeValueData = createAsyncThunk(
  "homeValue/fetch",
  async (_, { rejectWithValue }) => {
    const api = createApi();
    try {
      const response = await api.get(
        "/homekeeper_gethomevalueanddate"
      );

      // DEV ONLY
      //   await pause(10000);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// DEV ONLY
const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchHomeValueData };
