import Footer from '../footer/Footer';
import Header from '../header/Header';
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import createApi from "../../utils/api";
import InputMask from "react-input-mask";
import Sendotp from "../homepage/sendotp";

const phonePattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const namePattern = /^[a-zA-Z\s]+$/;
const validationSchema = Yup.object({
    name: Yup.string()
        .matches(namePattern, 'Kindly Check your Name')
        .min(2, 'Kindly Check your Name').required('Name is required')
        .max(50, 'Kindly Check your Name')
        .required('Name is required'),
    company: Yup.string()
        .min(2, 'Company Name should be at least 2 characters')
        .max(50, 'Company Name should be less than 50 characters')
        .required('Company Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    cellnumber: Yup.string()
        .matches(phonePattern, 'Not Valid Mobile Number')
        .required('Phone Number is required'),
    password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .max(18, 'Password should be less than 18 characters')
        .matches(/[A-Z]/, 'Required Strong Password')
        .matches(/\d/, 'Required Strong Password')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Required Strong Password')
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required')
});

function Registeragent() {
    const navigate = useNavigate();
    const api = createApi();
    const [viewpassword, setviewpassword] = useState(false);
    const [viewpassword1, setviewpassword1] = useState(false);
    const [optPage, setOptPage] = useState(0);
    const [email, setEmail] = useState("");
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };
    const togglePassword1 = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword1(!viewpassword1);
    };


    const handleSubmit = async (values, {setSubmitting, setErrors}) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('company', values.company);
        formData.append('cellnumber', values.cellnumber);
        formData.append('password', values.password);
        formData.append('password_confirmation', values.confirm_password);
        try {
            $(".addclass").hide();
            $("#buttonreplacement").show();
            api
                .post("/registerAgent", formData)
                .then(function (response) {
                    localStorage.setItem("token-type", response.data.usertype);
                    localStorage.setItem("user_name", response.data.name);
                    navigate("/dashboard");
                })
                .catch(function (error) {
                    $("#buttonreplacement").hide();
                    if (error.response && error.response.status === 422) {
                        const validationErrors = error.response.data.errors;
                        setErrors(validationErrors);
                    } else if (error.response && error.response.status === 403) {
                        setEmail(values.email);
                        setOptPage(1);
                    } else {
                        console.log("error", error);
                    }
                });
            $(".addclass").show();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        setSubmitting(false);
    };
    return (
        <>
            <Header />
            {optPage === 1 ? (
                <Sendotp
                    emailId={email}
                    setOtpStatus={optPage}
                />
            ) : (
                <Formik
                    initialValues={{
                        name: '', email: '', company: '', cellnumber: '', password: '', confirm_password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue }) => (
                        <>
                            <section className="login register">
                                <div className="container">
                                    <h1>Registeration</h1>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <Form className="form-signin text-start agentreg">
                                                <div className="form-group">
                                                    <Field type="text" name="name" className="form-control"
                                                           placeholder="Enter full name" />
                                                    <ErrorMessage name="name" component="span" className="errormessage" />
                                                </div>
                                                <div className="form-group">
                                                    <Field type="text" name="company" className="form-control"
                                                           placeholder="Company name" />
                                                    <ErrorMessage name="company" component="span" className="errormessage" />
                                                </div>
                                                <div className="form-group">
                                                    <Field name="cellnumber">
                                                        {({ field }) => (
                                                            <InputMask
                                                                {...field}
                                                                mask="999-999-9999"
                                                                maskChar=""
                                                                className="form-control inputnumber"
                                                                inputMode="numeric"
                                                                placeholder="Enter mobile number"
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="cellnumber" component="span" className="errormessage" />
                                                </div>
                                                <div className="form-group">
                                                    <Field type="email" name="email" className="form-control"
                                                           placeholder="Email" />
                                                    <ErrorMessage name="email" component="span" className="errormessage" />
                                                </div>
                                                <div className="form-group">
                                                <span className="password d-block">
                                                    <Field
                                                        type={viewpassword ? "text" : "password"}
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Password"
                                                    />
                                                    <i
                                                        className={viewpassword ? "far fa-eye" : "fa fa-eye-slash"}
                                                        onClick={togglePassword}
                                                    ></i>
                                                    <ErrorMessage name="password" component="span" className="errormessage" />
                                                </span>
                                                </div>
                                                <div className="form-group mb-3">
                                                <span className="password d-block">
                                                    <Field
                                                        type={viewpassword1 ? "text" : "password"}
                                                        name="confirm_password"
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                    />
                                                    <i
                                                        className={viewpassword1 ? "far fa-eye" : "fa fa-eye-slash"}
                                                        onClick={togglePassword1}
                                                    ></i>
                                                    <ErrorMessage name="confirm_password" component="span" className="errormessage" />
                                                </span>
                                                </div>
                                                <button
                                                    className="btn addclass"
                                                    type="submit"
                                                >
                                                    Start My Free Trial
                                                </button>
                                                <div id="buttonreplacement" style={{ margin: "30px" }}>
                                                    <img
                                                        src="//www.willmaster.com/images/preload.gif"
                                                        alt="loading..."
                                                    />
                                                </div>
                                                <p className="text-center">
                                                    by clicking “Start My Free Trial” you agree to the{" "}
                                                    <a href="#">Terms & Condition</a> and{" "}
                                                    <a href="#">Privacy Policy</a>
                                                </p>
                                            </Form>
                                        </div>
                                        <div className="col-sm-7 left_set">
                                            <h1>Close more deal with:</h1>
                                            <ul className="Closemore">
                                                <li>Full access to follow Up Boss team</li>
                                                <li>Accounts for everyone on your team</li>
                                                <li>Unlimited calling, texting and emailing</li>
                                                <li>Integration with 200+ lead sources</li>
                                                <li>iPhone & Android apps</li>
                                                <li>7-day-a-week support</li>
                                            </ul>
                                            <p className="lin mt-3">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry. Lorem Ipsum has been the industry's
                                                standard dummy text ever since the 1500s, when an unknown
                                                printer took a galley of type and scrambled it to make a type
                                                specimen book. It has survived not only five centuries, but
                                                also the leap into electronic typesetting,
                                            </p>

                                            <div className="usr">
                                                <div className="img">
                                                    <img src="asset/images/user.jpg" alt="img" />
                                                </div>
                                                <h4>Betty Cooker</h4>
                                                <p>Business Consultant</p>
                                                <p>The Ashton Real Estate Group</p>
                                                <p>#1RE?MAX team worldwide</p>
                                            </div>
                                            <h2>Trusted by top teams in North</h2>
                                            <h2 className="mb-3">America including:</h2>
                                            <ul className="upimg">
                                                <li>
                                                    <img src="asset/images/web1.png" alt="img" />
                                                </li>
                                                <li>
                                                    <img src="asset/images/web2.png" alt="img" />
                                                </li>
                                                <li>
                                                    <img src="asset/images/web3.png" alt="img" />
                                                </li>
                                                <li>
                                                    <img src="asset/images/web4.png" alt="img" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </Formik>
            )}
            <Footer />
        </>
    );

}

export default Registeragent;
