import { createAsyncThunk } from "@reduxjs/toolkit";
import createApi from "../../utils/api";

const fetchInspectors = createAsyncThunk(
  "inspectors/fetch",
  async (_, { getState, rejectWithValue }) => {
    const { profile } = getState();
    const api = createApi();
    try {
      const response = await api.get(
        "/homekeepermyhomeinspectorusers"
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// DEV ONLY
const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchInspectors };
