import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { setAddPropertyInvite } from "../../../store";
import createApi from "../../../utils/api";
import Loader from "../../loader_folder/Loader";

function EmailNotify({ item, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const dispatch = useDispatch();
  const api = createApi();

  const profileId = useSelector((state) => {
    return state.profile.data.id;
  });

  const clientId = useSelector((state) => {
    return state.client.data.id;
  });

  const owner = useSelector((state) => {
    return state.client.data.clientInfo;
  });

  const agent = useSelector((state) => {
    return state.profile.data;
  });

  const onSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const onBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = () => {
    setSubmitButtonDisable(true);
    let formData = new FormData();

    formData.append("id", clientId);
    formData.append("fname", owner.firstName);
    formData.append("email", owner.email);
    formData.append("agentName", agent.name);
    formData.append("agentId", agent.id);
    formData.append("subject", subject);
    formData.append("body", body);
    api
      .post("/notify_owner", formData)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Notified through email");
          dispatch(setAddPropertyInvite(response.data));
          onClose();
        } else {
          toast.error("Could not notify the owner");
          setSubmitButtonDisable(false);
        }
      })
      .catch((err) => {
        toast.error("Could not notify the owner");
        console.log(err);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex items-center flex-row mb-1">
        <input
          placeholder="Subject (Optional)"
          className="w-full text-sm placeholder-gray-400 focus:outline-none focus:ring-0 border-none form_input_style"
          id="email-notify-sub1"
          type="text"
          value={subject}
          onChange={onSubjectChange}
        />
      </div>
      <div className="h-px bg-gray-300 w-full mb-2"></div>
      <div className="flex items-center flex-row mb-12">
        <textarea
          placeholder="Body (Optional)"
          className="w-full text-sm placeholder-gray-400 focus:outline-none focus:ring-0 border-none resize-y min-h-32 max-h-96 form_input_style"
          id="email-notify-body1"
          type="text"
          value={body}
          onChange={onBodyChange}
        />
      </div>
      <div className="flex justify-end mx-2 mb-8">
        <button
          onClick={!submitButtonDisable ? handleSubmit : undefined}
          className={` px-2.5 py-2.5 text-sm rounded font-semibold flex items-center border text-white ${
            submitButtonDisable
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-sky-500 button_color"
          }`}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default EmailNotify;
