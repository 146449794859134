import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const createApi = () => {
    const api = axios.create({
        withCredentials: true,
        baseURL:
            process.env.REACT_APP_ENV === "production"
                ? process.env.REACT_APP_API_BASE_URL_PRODUCTION
                : process.env.REACT_APP_API_BASE_URL_DEVELOPMENT,
    });
    api.interceptors.request.use(async (config) => {
        if (['post', 'put', 'delete'].includes(config.method)) {
            const xsrfToken = Cookies.get("XSRF-TOKEN");
            if (!xsrfToken) {
                await api.get("/sanctum/csrf-cookie");
            }
        }
        return config;
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token-type");
                localStorage.removeItem("user_name");
                const navigate = useNavigate();
                navigate("/login");
            }
            return Promise.reject(error);
        }
    );

    return api;
};

export default createApi;
