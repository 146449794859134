import { createAsyncThunk } from "@reduxjs/toolkit";
import createApi from "../../utils/api";

const fetchInvitationPropertyList = createAsyncThunk(
  "propertyInvitation/fetch",
  async (_, { rejectWithValue }) => {
    const api = createApi();
    try {
      const response = await api.get("/get_invitation_list");

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export { fetchInvitationPropertyList };
