import { createAsyncThunk } from "@reduxjs/toolkit";
import createApi from "../../../utils/api";

const fetchProperties = createAsyncThunk(
  "homeOwnerPropertyList/fetch",
  async (_, { rejectWithValue }) => {
    const api = createApi();
    try {
      const response = await api.get(
        "/homekeeperviewmypropertylists"
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// DEV ONLY
const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchProperties };
