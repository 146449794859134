import Modal from "../../../common/Modal";
import ModalTitle from "../../../common/ModalTitle";
import EmailNotify from "../EmailNotify";

function EmailNotifyModal({ onClose }) {
  return (
    <>
      <Modal
        onClose={onClose}
        style={{
          top: "10%",
          left: "25%",
          width: "50%",
          height: "50%",
        }}
      >
        <div className="container mx-auto">
          <ModalTitle
            title="Draft Notification Email"
            cancel
            onCancel={onClose}
          />
          <EmailNotify onClose={onClose} />
        </div>
      </Modal>
    </>
  );
}

export default EmailNotifyModal;
