import createApi from "./api";
let cachedAprData = null;
const api = createApi();
export const getGlobalAprData = async () => {

    if (!cachedAprData) {
        try {
            const res = await api.get("/globalapr");
            cachedAprData = res.data.reduce((acc, value) => {
                acc[value.years] = value.percent;
                return acc;
            }, {});
        } catch (error) {
            console.error("APR:", error);
            return null;
        }
    }

    return cachedAprData;
};

export const getAprDataForYear = async (years) => {
    const aprData = await getGlobalAprData();
    return aprData ? aprData[years] : null;
};