import {Link, useNavigate} from 'react-router-dom';
import createApi from "../../utils/api";
import React, {useState} from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'), password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .max(18, 'Password should be less than 18 characters')
});

function AdminLogin() {
    const api = createApi();
    const navigate = useNavigate();
    const [viewpassword, setviewpassword] = useState(false);

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        let formdata = new FormData();
        formdata.append('email', values.email);
        formdata.append('password', values.password);
        api.post("/admin/login", formdata)
            .then(function (response) {
                localStorage.setItem('token-type', response.data.usertype);
                navigate('/admin/homeinspection/homeinspection');
            }).catch(function (error) {
            if (error.response && error.response.status === 422) {
                const validationErrors = error.response.data.errors;
                setErrors(validationErrors);
            } else {
                console.log("error", error);
            }
        });
        setSubmitting(false);
    }
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };
    return (<>
            <link rel="stylesheet" href="/../adminasset/css/style.css"/>
            <link
                rel="stylesheet"
                href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css"
                relation="stylesheet"
                type="text/css"
            />
            <section
                className="login adminlogin text-center"
                style={{backgroundImage: 'url(./adminasset/images/loginbg.png)'}}
            >
                <div className="container">
                    <div className="in_login">
                        <img src="adminasset/images/logo.png" alt="logo"/>

                        <Formik
                            initialValues={{email: '', password: ''}}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({isSubmitting}) => (<Form className="form-signin text-start mt-4">
                                    <h1>Log In</h1>
                                    <h6>Enter login credentials to access admin dashboard.</h6>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <span className="password d-block">
                                            <i className="fa-regular fa-envelope"></i>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Enter Email id"
                                            />
                                            <ErrorMessage name="email" component="div" className="error"/>
                                        </span>
                                    </div>

                                    <div className="form-group">
                                        <label>Password</label>
                                        <span className="password d-block">
                                            <Field
                                                type={viewpassword ? "text" : "password"}
                                                name="password"
                                                className="form-control"
                                                placeholder="Enter Password"
                                            />
                                            <i
                                                className={viewpassword ? "far fa-eye" : "fa fa-eye-slash ic"}
                                                aria-hidden="true"
                                                onClick={togglePassword}
                                            ></i>
                                            <ErrorMessage name="password" component="div" className="error"/>
                                        </span>
                                    </div>
                                    <p>
                                        <Link className="forg" to="/adminforgotpassword">Forgot Password?</Link>
                                    </p>
                                    <button className="btn" type="submit" disabled={isSubmitting}>
                                        Submit <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </Form>)}
                        </Formik>
                    </div>
                </div>
            </section>
        </>);
}


export default AdminLogin;


