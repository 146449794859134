import Mainfooter from "../footer/Mainfooter";
import Header from "../header/Header";
import Banner from "./Banner";

function MainPage() {
  return (
    <>
      <>
        <Header />
        <Banner />
        <Mainfooter />
      </>
    </>
  );
}

export default MainPage;
