import { createAsyncThunk } from "@reduxjs/toolkit";
import createApi from "../../utils/api";

const fetchInvitees = createAsyncThunk(
  "invitees/fetch",
  async (_, { getState, rejectWithValue }) => {
    const { profile } = getState();
    const api = createApi();
    try {
      const response = await api.get(
        "/homekeepergetinvitationusers"
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export { fetchInvitees };
